import React, {createContext, useContext, useState} from 'react';
import { removeToken } from "../helpers/localStorage";


const AuthContext = createContext <any> ({});

const { Provider }: any = AuthContext;

  

const AuthProvider =({ children } : any) =>{
    const token = localStorage.getItem('HISA_TOKEN');
    const expiresAt = localStorage.getItem('HISA_EXPIRES_AT');
    const role = localStorage.getItem('HISA_ROLE');
    const name = localStorage.getItem('HISA_NAME');
    const phone = localStorage.getItem('HISA_PHONE');
    const email = localStorage.getItem('HISA_ROLE');

   



    const [authState, setAuthState] = useState({
        token,
        expiresAt,
        role,
        name, phone, email
    })
    const setAuthInfo = ({token, expiresAt, role, name, phone, email}: any) =>{
        localStorage.getItem('HISA_TOKEN')
        localStorage.getItem('HISA_EXPIRES_AT')
        localStorage.getItem('HISA_ROLE')
        setAuthState({
            token,
            expiresAt,
            role,
            name,
            phone,
            email,

        })

    }
 

    const isAuthenticated = () => {
        var currentTime = new Date();
        // var timeOfExpiryString =  localStorage.getItem('HISA_EXPIRES_AT')
        var timeOfExpiryObject = new Date(localStorage.getItem('HISA_EXPIRES_AT'))


        if (!authState.token || !authState.expiresAt) {
            return false;
        }
        if(currentTime > timeOfExpiryObject ){
          return false;
        }

      return true
    };
   



    const isAdmin = () => {
      return authState.role === 'ADMIN';
    };

    const isReseller = () => {
        return authState.role === 'RESELLER';
      };

    const isBussiness = () => {
        return authState.role === 'BUSINESS';
      };

      const isNormal = () => {
        return authState.role === 'NORMAL';
      };

      const isUndefined = () => {
        return authState.role === '';
      };

    return(
        <Provider value={{
            authState,
            setAuthState: (authInfo: any) => setAuthInfo(authInfo),
            isAuthenticated,
isAdmin,
isReseller,
isNormal,
isBussiness,
        }}>
            {children}
        </Provider>
    )
}

export {AuthContext, AuthProvider}