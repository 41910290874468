import React, { Suspense, lazy, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  Router,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import IdleTimer from "react-idle-timer";

import { AuthProvider, AuthContext } from "./context/AuthContext";
import LogoutPopup from "./hooks/useAutoLogout";

import ReactGA from "react-ga";
const Homepage = lazy(() => import("./components/pages/homePage/HomePage"));
const history = createBrowserHistory();

//
// ========== AUTHENTICATION ==============
const Login = lazy(() => import("./components/pages/authentication/Login"));
const RegisterAs = lazy(() =>
  import("./components/pages/authentication/RegisterAs")
);
const Register = lazy(() =>
  import("./components/pages/authentication/Register")
);

const RegisterAsBusiness = lazy(() =>
  import("./components/pages/authentication/RegisterAsBusiness")
);

const RegisterAsAReseller = lazy(() =>
  import("./components/pages/authentication/RegisterAsAReseller")
);


const ForgotPassword = lazy(() =>
  import("./components/pages/authentication/ForgotPassword")
);
const ForgotPasswordConfirm = lazy(() =>
  import("./components/pages/authentication/ForgotPasswordConfirm")
);
const SendVerificationLink = lazy(() =>
  import("./components/pages/authentication/SendVerificationLink")
);
const ConfirmMailVerify = lazy(() =>
  import("./components/pages/authentication/ConfirmMailVerify")
);

const TopUpSummary = lazy(() =>
  import("./components/pages/topup/TopUpSummary")
);

const Credit = lazy(() => import("./components/pages/purchases/Credit"));
const BulkUpload = lazy(() => import("./components/pages/purchases/BulkUpload"));
const BulkDetails = lazy(() => import("./components/pages/purchases/BulkDetails"));

const NotificationsDetail = lazy(() =>
  import("./components/pages/notifications/NotificationsDetail")
);
const NotificationList = lazy(() =>
  import("./components/pages/notifications/NotificationList")
);


const BuyFuel = lazy(() =>
  import("./components/pages/fuel/Fuel")
);


//powerDetails
// SendVerificationLink
// ============ contact Us =================

const ContactUs = lazy(() => import("./components/pages/contactus/ContactUs"));

const 
AuthContactUs = lazy(() =>
  import("./components/pages/contactus/AuthContactUs")
);

// ============ my hisa Events =================

const MyhisaEvents = lazy(() =>
  import("./components/pages/hisaEvents/MyhisaEvents")
);

// ============ my hisa Blogs =================

const Blog = lazy(() => import("./components/pages/blog/Blog"));

// ============ my hisa about =================
const About = lazy(() => import("./components/pages/about/About"));


// ============ whyhisa =================
const WhyHisa = lazy(() => import("./components/pages/whyhisa/WhyHisa"));



// ============ my hisa features =================
const Features = lazy(() => import("./components/pages/features/Features"));
// ============ my hisa careers =================
const Careers = lazy(() => import("./components/pages/careers/Careers"));

// ============ DASHBOARD =================
// const Dashboard = lazy(() => import('./components/pages/dashboard/RegularDashboard'));
const Dashboard = lazy(() => import("./components/pages/dashboard/Dashboard"));

// ============ Topup =================
const Topup = lazy(() => import("./components/pages/topup/Topup"));
const PowerTopUp = lazy(() => import("./components/pages/power/PowerTopUp"));

const AuthenticatedSummary = lazy(() =>
  import("./components/pages/summary/AuthenticatedSummary")
);
const SummaryPage = lazy(() => import("./components/pages/summary/Summary"));

// ============ Power summary =================

const PowerSummary = lazy(() =>
  import("./components/pages/summary/PowerSummary")
);

const PowerDetails = lazy(() =>
  import("./components/pages/powerDetails/powerDetails")
);

const PaymentSuccess = lazy(() =>
  import("./components/pages/summary/PaymentSuccess")
);
const WalletTopup = lazy(() => import("./components/pages/topup/WalletTopup"));

// ============ HISTORY ===============
const ViewHistory = lazy(() =>
  import("./components/pages/history/ViewHistory")
);

const TrackTransaction = lazy(() =>
  import("./components/pages/history/TrackTransaction")
);

// NewHomepage

const NewHomePage = lazy(() =>
  import("./components/pages/newhomepage/NewHomePage")
);

// NEW CONTACT US
const NewContactUs = lazy(() =>
  import("./components/pages/contactus/NewContactUs")
);

// NEW HISA EVENTS
const NewMyHisaEvents = lazy(() =>
import("./components/pages/newhisaevents/NewMyHisaEvents")
);

// MY HISA EVENTS VIEW
const MyHisaEventsView = lazy(() =>
import("./components/pages/newhisaevents/MyHisaEventsView")
);
 
// NEW ABOUT
const NewAbout = lazy(() =>
import("./components/pages/newabout/NewAbout")
);

// NEw FEATURES
const NewFeatures = lazy(() =>
import("./components/pages/newfeatures/NewFeatures")
);

// NEW CAREERS
const NewCareers = lazy(() =>
import("./components/pages/newcareers/NewCareers")
);

// PROMOTIONS
const NewPromotions = lazy(() =>
import("./components/pages/newpromotions/NewPromotions")
);

// NEW DASHBOARD
const NewDashboard = lazy(() =>
import("./components/pages/newdashboard/NewDashboard")
);





// ============ HISTORY ===============
const Report = lazy(() => import("./components/pages/report/Report"));
const ChildReport = lazy(() => import("./components/pages/report/ChildReport"));

// ============ SETTINGS =============
const Settings = lazy(() => import("./components/pages/settings/Profile"));
const NewSettings = lazy(() => import("./components/pages/newsettings/Settings"));


const Children = lazy(() => import("./components/pages/children/Children"));

// ============ Message of the day =============
const motd = lazy(() => import("./components/pages/message/motd"));

// ============ WALLET =============
const ViewPurse = lazy(() => import("./components/pages/purse/ViewPurse"));

// ============ LOGOUT =============
const Logout = lazy(() => import("./components/pages/authentication/Logout"));

// ============ SCHEDULE =============
const Schedule = lazy(() =>
  import("./components/pages/schedule/CreateSchedule")
);
const ViewSchedule = lazy(() =>
  import("./components/pages/schedule/ViewSchedule")
);
const EditSchedule = lazy(() =>
  import("./components/pages/schedule/EditSchedule")
);

// ============ CONTACTS =============
const CreateContact = lazy(() =>
  import("./components/pages/contact/CreateContact")
);

const ViewContact = lazy(() =>
  import("./components/pages/contact/ViewContacts")
);

const viewPowerBeneficiary = lazy(() =>
  import("./components/pages/powerBeneficiary/viewPowerBeneficiary")
);

const viewCableBeneficiary = lazy(() =>
  import("./components/pages/cableBeneficiary/viewCableBeneficiary")
);

// ==========SMS==========
const SendSms = lazy(() =>
  import("./components/pages/sms/SendSms")
);

const CreateSmsSenderId = lazy(() =>
  import("./components/pages/sms/CreateSmsSenderId")
);



// ======WALLET TO WALLET TRANSFER=====
const WalletToWalletTransfer = lazy(() =>
  import("./components/pages/walletTransfer/WalletToWalletTransfer")
);

const PaymentMethod = lazy(() =>
  import("./components/pages/purchases/PaymentMethod")
);



function usePage() {
  useEffect(() => {
    ReactGA.initialize("UA-198800461-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.set({ page: window.location.pathname });
  }, []);
}

// change this when abel changes appropriately
const BussinessRoute = ({ children, ...rest }: any) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        auth.isBussiness() ? <>{children}</> : <Redirect to="/" />
      }
    ></Route>
  );
};

const AuthenticatedRoute = ({ children, ...rest }: any) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      render={() =>
        auth.isAuthenticated() ? <>{children}</> : <Redirect to="/" />
      }
    ></Route>
  );
};

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-198800461-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  usePage();

  const baseUrl = process.env.PUBLIC_URL;

  // const { match } = this.props

  return (
    <div style={{ height: "100%" }}>
      {/* <RouteChangeTracker> */}
      <BrowserRouter basename={baseUrl}>
        <Suspense fallback={<CustomLoader />}>
          <Switch>

            {/*========== DASHBOARD =========*/}
            {/* <AuthenticatedRoute path="/dashboard">
              <Dashboard />
            </AuthenticatedRoute> */}
            {/* <AuthenticatedRoute path="/dashboard" exact component={Dashboard} /> */}

            {/* <Route path="/" exact component={Homepage} /> */}

            {/* newhomepage */}
            <Route path="/" exact component={NewHomePage} />

            <AuthenticatedRoute path="/dashboard">
            <Route path="/dashboard" exact component={NewDashboard} />
            </AuthenticatedRoute> 

            <Route path="/payment-method" exact component={PaymentMethod} />


            {/* <Route path="/" exact component={Homepage} /> */}
            <Route path="/login" exact component={Login} />
            <Route path="/register-as" exact component={RegisterAs} />
            <Route path="/register" exact component={Register} />
            <Route path="/register-as-a-business" exact component={RegisterAsBusiness} />
            <Route path="/register-as-a-reseller" exact component={RegisterAsAReseller} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/reset-password/:uid"
              exact
              component={ForgotPasswordConfirm}
            />
            <Route
              path="/email/verify/:email/:token"
              exact
              component={ConfirmMailVerify}
            />
            <Route
              path="/resend-verification-link"
              exact
              component={SendVerificationLink}
            />



            <Route path="/track-transaction" exact component={TrackTransaction} />


            

              <Route
                path="/summary/:network/phone/:phone"
                exact
                component={SummaryPage}
              />
              <Route
                path="/auth/topup/summary/"
                exact
                component={TopUpSummary}
              />
              <Route path="/power-summary" exact component={PowerSummary} />
              <Route
                path="/auth/summary/:network/phone/:phone"
                exact
                component={AuthenticatedSummary}
              />

              {/* ======== AUTHENTICATION =========*/}
              <Route path="/top-up" exact component={Credit} />

              {/* bulk upload */}
              <Route path="/bulk-upload" exact component={BulkUpload} />
              <Route path="/bulk-details/:id" exact component={BulkDetails} />



              {/*  */}

              {/*==========Power TOPUP =========*/}
              <Route path="/buy-power" exact component={PowerTopUp} />

              {/*==========Contact US =========*/}
              <Route path="/contact-us" exact component={ContactUs} />
              <Route path="/auth-contact-us" exact component={AuthContactUs} />

              {/*==========My hisa events =========*/}
              {/* <Route path="/myhisa-events" exact component={MyhisaEvents} /> */}

              {/* NEW HISA EVENTS */}
              <Route path="/myhisa-events" exact component={NewMyHisaEvents} />

              {/* MY HISA EVENTS vIEW*/}
              <Route path="/myhisa-events-view" exact component={MyHisaEventsView} />


              {/*==========My hisa blog =========*/}
              <Route path="/blog" exact component={Blog} />

              {/*==========My hisa about =========*/}
              <Route path="/about" exact component={About} />

              {/*==========My hisa Features =========*/}
              <Route path="/features" exact component={Features} />

              {/*==========My hisa Careers =========*/}
              <Route path="/careers" exact component={Careers} />

              {/*========== TOPUP =========*/}
              <Route path="/topup" exact component={Topup} />

              <Route
                path="/payment-successful"
                exact
                component={PaymentSuccess}
              />
              <Route path="/purse/topup" exact component={WalletTopup} />

              {/*========== HISTORY =========*/}
              <Route path="/history" exact component={ViewHistory} />

              {/*========== Report Page =========*/}
              <Route path="/report" exact component={Report} />

              <Route
                path="/child-report/:email"
                exact
                component={ChildReport}
              />

              {/*==========powerDetails=========*/}
              <Route path="/power/details/:id" exact component={PowerDetails} />

              {/*========== SETTINGS =========*/}
              <Route path="/settings" exact component={Settings} />

              <Route path="/new-settings" exact component={NewSettings} />






              {/*========== WALLET =========*/}
              <Route path="/purse" exact component={ViewPurse} />

              {/*========== WALLET =========*/}
              <Route
                path="/notification/:id"
                exact
                component={NotificationsDetail}
              />

              <Route path="/notifications" exact component={NotificationList} />

              {/*========== SCHEDULE =========*/}
              <Route path="/schedule/create" exact component={Schedule} />
              <Route path="/schedule" exact component={ViewSchedule} />
              <Route path="/schedule/edit" exact component={EditSchedule} />

              {/*========== CONTACT =========*/}
              <Route path="/contacts" exact component={ViewContact} />
              <Route path="/power-beneficiaries" exact component={viewPowerBeneficiary} />
              <Route path="/cable-beneficiaries" exact component={viewCableBeneficiary} />

              {/* sms */}
              <Route path="/send-sms" exact component={SendSms} />
              <Route path="/create-sms-sender" exact component={CreateSmsSenderId} />

 
              {/* ====WALLET TO WALLET TRANSFER */}
              <Route path="/wallet-transfer" exact component={WalletToWalletTransfer} />



              {/* NEW CONTACT US */}
              <Route path="/new-contactus" exact component={NewContactUs} />



              <Route path="/why-hisa" exact component={WhyHisa} />

            
              {/* NEW ABOUT */}
              <Route path="/new-about" exact component={NewAbout} />

              {/* NEW FEATURES */}
              <Route path="/new-features" exact component={NewFeatures} />

              {/* NEW CAREERS */}
                <Route path="/new-careers" exact component={NewCareers} />

              {/* NEW PROMOTIONS */}
                <Route path="/new-promotions" exact component={NewPromotions} />






              <BussinessRoute path="/resellers">
                <Children />
              </BussinessRoute>

              {/*========== LOGOUT =========*/}
              <Route path="/logout" exact compocreatesms-senderidnent={Logout} />

              <Route path="/showLoader" exact component={CustomLoader} />

              {/*========== DASHBOARD =========*/}
              <Route path="/message" exact component={motd} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;

const CustomLoader = () => {
  return (
    <div style={{ width: "inherit", height: "inherit" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "inherit",
        }}
      >
        <Loader
          type="RevolvingDot"
          color="#1527C3"
          height={300}
          width={100}
          timeout={0}
        />
      </div>
    </div>
  );
};
