const keyWord: string = "HISA";

export const getToken = () => {
  return localStorage.getItem(keyWord + "_TOKEN");
};

export const setToken = (token: string) => {
  localStorage.setItem(keyWord + "_TOKEN", token);
};

export const setName = (name: string) => {
  localStorage.setItem(keyWord + "_NAME", name);
};

export const setPhone = (phone: string) => {
  localStorage.setItem(keyWord + "_PHONE", phone);
};

export const setEmail = (email: string) => {
  localStorage.setItem(keyWord + "_EMAIL", email);
};
export const clearStorage = () => {
  localStorage.clear();
};

export const removeToken = () => {
  localStorage.removeItem(keyWord + "_TOKEN");
};

export const getBonusToken = () => {
  return localStorage.getItem(keyWord + "_BONUS");
};

export const setBonusToken = (token: string) => {
  localStorage.setItem(keyWord + "_BONUS", token);
};

export const removeBonusToken = () => {
  localStorage.removeItem(keyWord + "_BONUS");
};

export const getBonusTokenAmount = () => {
  return localStorage.getItem(keyWord + "_BONUS_AMOUNT");
};

export const setBonusTokenAmount = (token: string) => {
  localStorage.setItem(keyWord + "_BONUS_AMOUNT", token);
};
export const removeBonusAmount = () => {
  localStorage.removeItem(keyWord + "_BONUS_AMOUNT");
};

export const getExpiresAt = () => {
  return localStorage.getItem(keyWord + "_EXPIRES_AT");
};

export const setExpiresAt = (expiresAt: string) => {
  localStorage.setItem(keyWord + "_EXPIRES_AT", expiresAt);
};

export const getRole = () => {
  return localStorage.getItem(keyWord + "_ROLE");
};

export const setRole = (role: string) => {
  localStorage.setItem(keyWord + "_ROLE", role);
};

var HISA_POWER_OBJECT: Array<object> = [];

export const setPowerDetails = (powerTokens: any) => {
  localStorage.setItem("HISA_POWER_OBJECT", powerTokens);
};

export const getPowerDetails = () => {
  return localStorage.getItem("HISA_POWER_OBJECT");
};
