import axios, { AxiosInstance } from "axios";
import { getToken } from "../helpers/localStorage";

// const apiUrl: string = "http://94.229.72.27:85/api/";
// const apiUrl: string = "http://localhost:8009/api/";
const apiUrl: string = "https://api.myhisa.com/api";

type AxiosHeader = {
  Accept: string;
  "Content-Type": string;
};
const headers: {
  Accept: string;
  "Content-Type": string;
  Authorization: string;
} = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "Bearer " + getToken(),
};

const instance: AxiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 50000,
  headers,
});

instance.interceptors.response.use(
  function(response) {
    //console.log(response)
    let finalResponse = { ...response, status: 200, statusCode: 200 };
    return finalResponse;
  },
  function(error) {
    // //console.log(error.response.status);

    // //console.log(error.response.data.data.diagnostics.message);
    if (error?.response?.status === 401) {
      return redirectToLogin();
    } else {
      let finalResponse = {
        ...error,
        status: error?.response?.status,
        statusCode: error?.response?.status,
      };
      return finalResponse;
    }
    //console.log(error);
  }
);

function redirectToLogin() {
  let publicUrl = process.env.PUBLIC_URL;
  let loginPageUrl = null;
  loginPageUrl = publicUrl + "/login";

  let origin = window.location.origin;
  if (window.location.origin === origin + loginPageUrl) return;
  window.location.href = loginPageUrl;
}

function processResult(response: any) {
  let { statusCode } = response;
  // //console.log(response);
  if (statusCode === 200) {
    return {
      success: response?.data?.status,
      data: response?.data?.data,
      message: response?.data?.message,
      status_code: response?.data?.status_code || 200,
    };
  } else if (statusCode === 500) {
    return {
      success: false,
      message:
        "Something went wrong, our developers have been informed and we are working on it.",
    };
  }
  return {
    success: response?.response?.data?.status,
    message: response?.response?.data?.message,
  };
  // , diagnostics:response.data.data.diagnostics.message
}

//Power Fee
export async function getPowerFee(): Promise<any> {
  const result = await instance.get("/fees/get?type=power");
  return processResult(result);
}
//guest Power Fee
export async function getguestPowerFee(): Promise<any> {
  const result = await instance.get("/guest/fees/get?type=power");
  return processResult(result);
}

//cable subscription
export async function listBouquetById(bqId: any): Promise<any> {
  const result = await instance.get(`/cable/bouquet/${bqId}`);
  return processResult(result);
}

//check cable subscription
export async function verifycable(data: any): Promise<any> {
  const result = await instance.post("cable/check", data);
  return processResult(result);
}

//purchase cables

// {{host}}/cable/purchase

export async function purchaseCable(data: any): Promise<any> {
  const result = await instance.post("cable/purchase", data);
  return processResult(result);
}

// get cables
export async function getCables(): Promise<any> {
  const result = await instance.get("cable/disco");
  return processResult(result);
}

export async function fetchAirtimeTransaction(
  transaction_id: any
): Promise<any> {
  const result = await instance.get(`guest/check/purchase/${transaction_id}`);
  return processResult(result);
}

export async function fetchUtilityTransaction(
  transaction_id: any
): Promise<any> {
  const result = await instance.get(`guest/check/bills/${transaction_id}`);
  return processResult(result);
}

export async function getBouquet(bqId: any): Promise<any> {
  const result = await instance.get(`cable/disco/${bqId}`);
  return processResult(result);
}

export async function getCableRef(data: any): Promise<any> {
  const result = await instance.post("guest/get-ref", data);
  return processResult(result);
}

// {{host}}/cable/bouquet/2

export async function register(data: any): Promise<any> {
  const result = await instance.post("auth/register", data);
  return processResult(result);
}

export async function login(data: any): Promise<any> {
  const result = await instance.post("auth/login", data);
  return processResult(result);
}

export async function requestForgotPassword(data: any): Promise<any> {
  const result = await instance.post("forgot-password/send-link", data);
  return processResult(result);
}
export async function resetForgotPassword(data: any): Promise<any> {
  const result = await instance.post("forgot-password/reset", data);
  return processResult(result);
}

export async function verifyAccountConfirm(data: any): Promise<any> {
  const result = await instance.post("/auth/mail-verification/verify", data);
  return processResult(result);
}

// wallet to wallet transfer
export async function walletToWalletTransfer(data: any): Promise<any> {
  const result = await instance.post("/wallet/transfer", data);
  return processResult(result);
}

export async function resendVerificationMail(): Promise<any> {
  const result = await instance.get("/auth/mail-verification/send");
  return processResult(result);
}

export async function getPowerBeneficiary(): Promise<any> {
  const result = await instance.get("/power/benefitiary");
  return processResult(result);
}

export async function getCableBeneficiary(): Promise<any> {
  const result = await instance.get("/cable/benefitiary");
  return processResult(result);
}

// SMS SENDER ID
export async function getSmsSenderId(): Promise<any> {
  const result = await instance.get("/sms/sender-id?status=APPROVED");
  return processResult(result);
}

export async function createSmsSenderId(data: any): Promise<any> {
  const result = await instance.post("/sms/create-sender-id ", data);
  return processResult(result);
}

export async function sendMultipleSms(data: any): Promise<any> {
  const result = await instance.post("/sms/send", data);
  return processResult(result);
}

export async function getNetworks(): Promise<any> {
  const result = await instance.get("networks");
  return processResult(result);
}

export async function getBundlesByNetwork(networkId: BigInteger): Promise<any> {
  const result = await instance.get(`bundles?network=${networkId}`);
  return processResult(result);
}

export async function airtimeTOContact(data: any): Promise<any> {
  const result = await instance.post("purchase/contact", {
    type: data.type,
    amount: +data.amount,
    contact: data.contact,
  });
  return processResult(result);
}

export async function airtimeTOGroup(data: any): Promise<any> {
  const result = await instance.post("purchase/contact", {
    type: data.type,
    amount: +data.amount,
    group: data.group,
  });
  return processResult(result);
}

// {{host}}/purchase/contact?type=AIRTIME&amount=1000&contact=18&group=3

export async function testEndpoint(): Promise<any> {
  const result = await instance.get("test-responses");
}

export async function purchaseAirtime(data: any): Promise<any> {
  const result = await instance.post("purchase/airtime", data);
  return processResult(result);
}

export async function purchaseData(data: any): Promise<any> {
  const result = await instance.post("purchase/data", data);
  return processResult(result);
} 

export async function getTransactionHistory(startDate:string, endDate:string,status:string, type:string,limit:number, startId:string): Promise<any> {
  const filterOptions = [];
  let apiUrl = 'purchase/history';

  if (startDate) {
    filterOptions.push(`start_date=${startDate}`);
  }if (endDate) {
    filterOptions.push(`end_date=${endDate}`);
  }if (status) {
    filterOptions.push(`status=${status}`);
  }if (type) {
    filterOptions.push(`type=${type}`);
  }if (limit) {
    filterOptions.push(`limit=${limit}`);
  }if (startId) {
    filterOptions.push(`start_id=${startId}`);
  }if (filterOptions.length > 0) {
    apiUrl += `?${filterOptions.join('&')}`;
  }
  const result = await instance.get(apiUrl);
  return processResult(result);
  // const result = await instance.get(`purchase/history?limit=${limit}`);
  // return processResult(result);
}


export async function getPowerTransactionHistory(): Promise<any> {
  const result = await instance.get("power/history");
  return processResult(result);
}

export async function getUserDetail(): Promise<any> {
  const result = await instance.get(`auth/me`);
  return processResult(result);
}

export async function getDetailedUserDetail(): Promise<any> {
  const result = await instance.get(`wallet/balance`);
  return processResult(result);
}

export async function getAirtimeRef(
  phoneNumber: string,
  amount: BigInteger,
  network: BigInteger,
  description?: string
): Promise<any> {
  const result = await instance.get(
    `guest/get-ref?phone_number=${phoneNumber}&purchase=AIRTIME&amount=${amount}&network=${network}&description=${description}`
  );
  return processResult(result);
}

export async function getDataRef(
  phoneNumber: string,
  bundle: string,
  description?: string
): Promise<any> {
  const result = await instance.get(
    `guest/get-ref?phone_number=${phoneNumber}&purchase=DATA&bundle=${bundle}&description=${description}`
  );
  return processResult(result);
}

export async function getPowerRef(
  phoneNumber: string,
  purchase: string,
  amount: string,
  email: string,
  meterNo: string,
  discoCode: string,
  vendType: string,
  address: string,
  name: string,
  maxVendAmount: string,
  minVendAmount: string
): Promise<any> {
  const result = await instance.get(
    `guest/get-ref?phone_number=${phoneNumber}&purchase=${purchase}&amount=${amount}&email=${email}&meterNo=${meterNo}&discoCode=${discoCode}&vendType=${vendType}&address=${address}&name=${name}&maxVendAmount=${maxVendAmount}&minVendAmount=${minVendAmount}`
  );
  return processResult(result);
}

// {{host}}/guest/get-ref?phone_number=07034481876&purchase=POWER&amount=150000&email=abonuoha@gmail.com&meterNo=0000000000&discoCode=EKO&vendType=PREPAID&address=Zaria&name=Elizabeth Onuoha&maxVendAmount=50000&minVendAmount=500

export async function verifyPayment(
  txRef: string,
  transactionId: string
): Promise<any> {
  const result = await instance.get(
    `guest/validate?tx_ref=${txRef}&transaction_id=${transactionId}`
  );
  return processResult(result);
}

export async function getLastSevenDaysTransaction(): Promise<any> {
  const result = await instance.get(`purchase/history/last7days`);
  return processResult(result);
}

export async function getWalletRef(
  amount: BigInteger,
  wallet: string,
  description?: string
): Promise<any> {
  const results = await instance.get(
    `credit/get-ref?amount=${amount}&wallet=${wallet}&description=${description}`
  );
  return processResult(results);
}

export async function verifyWalletTopup(
  txRef: string,
  transactionId: string
): Promise<any> {
  const result = await instance.get(
    `credit/validate?tx_ref=${txRef}&transaction_id=${transactionId}`
  );
  return processResult(result);
}

export async function queryPowerTransaction(
  transactionId: string
): Promise<any> {
  const result = await instance.get(
    `/guest/power/query-status?transaction_id=${transactionId}`
  );
  return processResult(result);
}

// {{host}}/guest/power/query-status?transaction_id={transaction_id}

export async function getContacts(): Promise<any> {
  const results = await instance.get(`contacts`);
  return processResult(results);
}

export async function postContact(data: any): Promise<any> {
  const result = await instance.post("contacts", data);
  return processResult(result);
}

export async function postGroup(data: any): Promise<any> {
  const result = await instance.post("groups", data);
  return processResult(result);
}

export async function getGroups(): Promise<any> {
  const results = await instance.get(`groups`);
  return processResult(results);
}

export async function getSchedules(): Promise<any> {
  const results = await instance.get(`schedules`);
  return processResult(results);
}

export async function postPowerSchedule(formData: object): Promise<any> {
  const results = await instance.post("/bill-schedules", formData);
  return processResult(results);
}

export async function postSchedule(data: any): Promise<any> {
  const results = await instance.post(`schedules`, data);
  return processResult(results);
}

// export async function purchaseMeter (formData: any): Promise<any> {
//     const result = await instance.post("/power/purchase", formData);
//     return processResult(result);
// }

export async function deleteSchedule(
  scheduleId: string,
  data: any
): Promise<any> {
  const result = await instance.delete(`/schedules/${scheduleId}`, data);
  return processResult(result);
}

export async function getProfile(): Promise<any> {
  const result = await instance.get("auth/me");
  return processResult(result);
}

export async function postProfile(data: any): Promise<any> {
  const result = await instance.post("auth/me", data);
  return processResult(result);
}

export async function postPassword(data: any): Promise<any> {
  const result = await instance.post("auth/change_password", data);
  return processResult(result);
}

export async function postPinReset(data: any): Promise<any> {
  const result = await instance.post("/auth/change-pin-initiate", data);
  return processResult(result);
}

export async function viewApiKey(data: any): Promise<any> {
  const result = await instance.post("/auth/apikey-view", data);
  return processResult(result);
}

// confusion on this two endpoints

export async function createPin(data: any): Promise<any> {
  const result = await instance.post("/auth/change-pin-verify", data);
  return processResult(result);
}

export async function postPinVerify(data: any): Promise<any> {
  // const result = await instance.post("/auth/create-pin  ", data);
  const result = await instance.post("/auth/change-pin-verify  ", data);
  return processResult(result);
}

export async function fileUpload(data: any): Promise<any> {
  const result = await instance.post("/contacts/bulk-group", data);
  return processResult(result);
}

export async function contactFileUpload(data: any): Promise<any> {
  const result = await instance.post("/contacts/bulk", data);
  return processResult(result);
}

export async function topupHistory(data: any): Promise<any> {
  const result = await instance.post("/purchase/export/report", data);
  return processResult(result);
}

export async function bulkTxnReapeat(data: any): Promise<any> {
  const result = await instance.post("/purchase/repeat/bulk", data);
  return processResult(result);
}

// const result = await instance.post("/auth/change-pin-verify  ", data);

export async function addContactToGroup(data: any): Promise<any> {
  const result = await instance.post("/groups/add-contact", data);
  return processResult(result);
}

export async function deleteContactGroup(groupId: string): Promise<any> {
  const result = await instance.delete(`/groups/${groupId}`);
  return processResult(result);
}

export async function editContact(contactId: string, data: any): Promise<any> {
  const result = await instance.post(`/contacts/${contactId}`, data);
  return processResult(result);
}

export async function deleteContact(
  contactId: string,
  data: any
): Promise<any> {
  const result = await instance.delete(`/contacts/${contactId}`, data);
  return processResult(result);
}

export async function contactAirtimeTopup(
  amount: string,
  network: string,
  contact: string
): Promise<any> {
  const result = await instance.get(
    `/purchase/contact?type=AIRTIME&amount=${amount}&network=${network}&contact=${contact}`
  );
  return processResult(result);
}

export async function contactDataTopup(
  bundle: string,
  contact: string
): Promise<any> {
  const result = await instance.get(
    `/purchase/contact?type=DATA&bundle=${bundle}&contact=${contact}`
  );
  return processResult(result);
}

export async function getMotd(): Promise<any> {
  const result = await instance.get("/daily-message/today");
  return processResult(result);
}

export async function postMotd(data: any): Promise<any> {
  const result = await instance.post("/daily-message", data);
  return processResult(result);
}

export async function getAllMotd(): Promise<any> {
  const results = await instance.get("/daily-message");
  return processResult(results);
}

export async function setMotd(data: any): Promise<any> {
  const result = await instance.post("/daily-message/today", data);
  return processResult(result);
}

export async function RepeatAirtimeTransaction(data: any): Promise<any> {
  const result = await instance.post("/purchase/repeat", data);
  return processResult(result);
}

export async function getDebitReport(
  from: string,
  to: string,
  wallet: string,
  type: string
): Promise<any> {
  const result = await instance.get(
    `/purchase/history/summary?from=${from}&to=${to}&wallet=${wallet}&type=${type}`
  );
  return processResult(result);
}

export async function getCreditReport(
  from: string,
  to: string,
  wallet: string,
  method: string
): Promise<any> {
  const result = await instance.get(
    `/credit/history/summary?from=${from}&to=${to}&wallet=${wallet}&method=${method}`
  );
  return processResult(result);
}

export async function getReport(from: string, to: string): Promise<any> {
  const result = await instance.get(`/wallet/history?from=${from}&to=${to}`);
  return processResult(result);
}

export async function getChildReport(
  from: string,
  to: string,
  child: string
): Promise<any> {
  const result = await instance.get(
    `/wallet/history?from=${from}&to=${to}&child=${child}`
  );
  return processResult(result);
}

//Purchase power

export async function getDiscos(): Promise<any> {
  const result = await instance.get("power/discos");
  return processResult(result);
}

export async function checkMeter(
  meterNo: string,
  discoCode: string
): Promise<any> {
  const result = await instance.get(
    `/power/check?meterNo=${meterNo}&discoCode=${discoCode}`
  );
  return processResult(result);
}

export async function purchaseMeter(formData: any): Promise<any> {
  const result = await instance.post("/power/purchase", formData);
  return processResult(result);
}

export async function getMyMeters(): Promise<any> {
  const result = await instance.get("power/saved");
  return processResult(result);
}

//guest power endpoints

export async function getGuestDiscos(): Promise<any> {
  const result = await instance.get("guest/power/discos");
  return processResult(result);
}

export async function guestCheckMeter(
  meterNo: string,
  discoCode: string
): Promise<any> {
  const result = await instance.get(
    `/guest/power/check?meterNo=${meterNo}&discoCode=${discoCode}`
  );
  return processResult(result);
}

export async function giveToCharity(bonusNumber: string): Promise<any> {
  const result = await instance.get(
    `/guest/bonus-to-charity?bonus=${bonusNumber}`
  );
  return processResult(result);
}

export async function creditChild(formData: any): Promise<any> {
  const result = await instance.post("/wallet/transfer", formData);
  return processResult(result);
}

export async function purchaseFuel(formData: any): Promise<any> {
  const result = await instance.post("/purchase/fuel", formData);
  return processResult(result);
}

export async function walletTransferHistory(): Promise<any> {
  const result = await instance.get("/wallet/transfer-history");
  return processResult(result);
}

export async function getNotifications(): Promise<any> {
  const result = await instance.get("/auth/notification");
  return processResult(result);
}

export async function markAllNotificationsAsSeen(): Promise<any> {
  const result = await instance.post("/auth/notification/mark-all-as-seen");
  return processResult(result);
}

export async function markNotificationAsSeen(
  notificationId: any
): Promise<any> {
  const result = await instance.post(
    `/auth/notification/mark-seen/${notificationId}`
  );
  return processResult(result);
}

export async function createAccountNumber(formData: any): Promise<any> {
  const result = await instance.post(
    "v2/user/banks/generate-reserved-account",
    formData
  );
  return processResult(result);
}

export async function createTemporaryAccountNumber(
  formData: any
): Promise<any> {
  const result = await instance.post(
    "/v2/user/banks/generate-temporary-account",
    formData
  );
  return processResult(result);
}

export async function getTemporaryAccountTransactions(
  tempAccountID: number
): Promise<any> {
  const result = await instance.get(
    `/v2/user/banks/temp-account-transactions/${tempAccountID}`
  );
  return processResult(result);
}

export async function fetchCableBillers(): Promise<any> {
  const result = await instance.get("v2/bills/biller-cable");
  return processResult(result);
}

export async function fetchCableBillerItems(biller_id: string): Promise<any> {
  const result = await instance.get(`v2/bills/payment-item/${biller_id}`);
  return processResult(result);
}

export async function makePayment(formData: any): Promise<any> {
  const result = await instance.post("/v2/bills/payment", formData);
  return processResult(result);
}

export async function checkCustomer(formData: any): Promise<any> {
  const result = await instance.post("/v2/bills/check-customer", formData);
  return processResult(result);
}

export async function payBillsv2(formData: any): Promise<any> {
  const result = await instance.post("/v2/bills/payment", formData);
  return processResult(result);
}

export async function getBillRefV2(data: any): Promise<any> {
  const result = await instance.post("/guest/get-ref", data);
  return processResult(result);
}

export async function bulkListUploads(): Promise<any> {
  const result = await instance.get(`/purchase/bulk/list`);
  return processResult(result);
}



export async function bulkFileUpload(formData: any): Promise<any> {
  const result = await instance.post(`/purchase/bulk/upload-airtime`, formData);
  return processResult(result);
}


export async function getBulkUploadDetails(id: string): Promise<any> {
  const result = await instance.get(`/purchase/bulk/view/${id}`);
  return processResult(result);
}


export async function processUploads(formData: any): Promise<any> {
  const result = await instance.post(`/purchase/bulk/upload-process`, formData);
  return processResult(result);
}


export async function retryFailed(formData: any): Promise<any> {
  const result = await instance.post(`/purchase/bulk/upload-retry-failed`, formData);
  return processResult(result);
}